import { Link } from "gatsby"
import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import findCir from "../../../images/ruby/findCir.svg"
import findPlus from "../../../images/ruby/findPlus.svg"
import findRec from "../../../images/ruby/findRec.svg"
import findRec2 from "../../../images/ruby/findRec2.svg"
import * as styles from "./SubServices.module.scss"

const SubServices = ({ strapiData }) => {
  return (
    <section className={`${styles.subservice}`}>
      <div className={styles.findPlus}>
        <img src={findPlus} decoding="async" loading="lazy" alt="plus" />
      </div>
      <div className={styles.findRec2}>
        <img src={findRec2} decoding="async" loading="lazy" alt="rectangle" />
      </div>
      <Container>
        <div className={styles.findRec}>
          <img src={findRec} decoding="async" loading="lazy" alt="rectangle" />
        </div>
        <div className={styles.findCir}>
          <img src={findCir} decoding="async" loading="lazy" alt="circle" />
        </div>
        <div
          className={`p-1 p-md-3 ${styles.subserviceHead}`}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className="pt-5">
          {strapiData?.cards &&
            strapiData?.cards?.map((el, i) => (
              <Col md={6} lg={4} xs={12} className="p-0 p-md-2 mb-lg-5" key={i}>
                <Card className={styles.subserviceCard}>
                  <Card.Body>
                    <div className="pb-3">
                      <img
                        src={el?.image1[0]?.localFile?.publicURL}
                        alt={el?.image1[0]?.alternativeText}
                        decoding="async"
                        loading="lazy"
                        height={40}
                        width={40}
                      />
                    </div>
                    <Card.Title>
                      <h3 className={styles.h5}>{el?.title}</h3>
                    </Card.Title>
                    <Card.Text
                      className={styles.cardText}
                      dangerouslySetInnerHTML={{
                        __html: el?.description?.description,
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <div className="text-center">
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className="btn_black_border btn btn-primary"
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </section>
  )
}

export default SubServices
