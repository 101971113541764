import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Offering.module.scss"

const Offering = ({ strapiData }) => {
  return (
    <div className={styles.subservice}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p>
          Generate higher results and revenue with your exclusively built MVP{" "}
        </p>
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col xs={12} md={6} lg={4} key={i}>
                <div className={styles.card}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt={e?.image1[0]?.alternativeText}
                  />
                  <h3>{e.title}</h3>
                </div>
              </Col>
            ))}
        </Row>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
      </Container>
    </div>
  )
}

export default Offering
