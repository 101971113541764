import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Ribon.module.scss"

const Ribon = ({ strapiData }) => {
  return (
    <div className={styles.Ribon}>
      <Container>
        <Row className="gap-30">
          <Col md={12} lg={6}>
            <h2
              className={styles.head}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            {strapiData?.buttons && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={`btn_black_border btn btn-primary ${styles.btn}`}
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </Col>
          <Col md={12} lg={6} className={`text-right ${styles.IconAlignment}`}>
            {strapiData.secImages && (
              <img
                decoding="async"
                loading="lazy"
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.secImages[0]?.alternativeText}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Ribon
