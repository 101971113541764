// extracted by mini-css-extract-plugin
export var benefit = "Benefits-module--benefit--212c3";
export var box1 = "Benefits-module--box1--b4a54";
export var box2 = "Benefits-module--box2--c6676";
export var boxParent = "Benefits-module--boxParent--27830";
export var button = "Benefits-module--button--d27df";
export var desc = "Benefits-module--desc--cda88";
export var heading = "Benefits-module--heading--f3c48";
export var imageBox = "Benefits-module--imageBox--2a28c";
export var mvc = "Benefits-module--mvc--15f8a";
export var text = "Benefits-module--text--4ba20";
export var top = "Benefits-module--top--ec859";