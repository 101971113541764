import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Efficient.module.scss"

const Efficient = ({ strapiData }) => {
  return (
    <div className={styles.Efficient}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col xs={12} md={6} lg={4} key={i}>
                <div className={styles.card}>
                  <div className={styles.topSection}>
                    <div className={styles.box}>{i + 1}</div>
                    <h3>{e?.title} </h3>
                  </div>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Efficient
