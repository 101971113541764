import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Curious.module.scss"

const TextBtnStrip2 = ({ link, strapiData }) => {
  return (
    <section className={styles.section}>
      <Container>
        <Row
          className={`align-items-center justify-content-between ${styles.Row}`}
        >
          <Col xl={8}>
            <h2
              className={`text-light mb-0 ${styles.textAlignment}`}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          </Col>
          <Col xl={4} className={styles.Btn}>
            <Link to={link}>
              <Link
                to={strapiData.buttons[0].url}
                variant="light mt-3 mt-xl-0"
                className="btn_white_border"
              >
                {strapiData.buttons[0].title}
              </Link>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TextBtnStrip2
