import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import mvpline from "../../../images/mvc/mvpline.svg"
import * as styles from "./Services.module.scss"

const Services = ({ strapiData }) => {
  return (
    <div
      className={`${styles.service} ${styles.head} ${
        strapiData?.description?.description && styles.withdesc
      }`}
    >
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        {strapiData?.description?.description && (
          <div
            className={styles.subheading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.description?.description,
            }}
          />
        )}
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((item, index) => {
              return (
                <Col
                  lg={6}
                  md={6}
                  xs={12}
                  key={index}
                  style={{ display: "flex" }}
                >
                  <div className={styles.box}>
                    <h3>{item?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.description?.description,
                      }}
                    />
                  </div>
                </Col>
              )
            })}
        </Row>
      </Container>
      <div className={styles.mvpLines}>
        <img src={mvpline} decoding="async" loading="lazy" alt="background" />
      </div>
    </div>
  )
}

export default Services
