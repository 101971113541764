import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Technologies2.module.scss"

const Technologies = ({ strapiData, bg }) => {
  return (
    <section
      className="p-85"
      style={{ backgroundColor: bg ? "#F8F8F8" : "#FFFFFF" }}
    >
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="align-items-center gap-50">
          {strapiData?.cards?.map((el, i) => (
            <Col
              lg={2}
              md={4}
              xs={6}
              className="text-center"
              key={i}
              style={{ padding: 15 }}
            >
              <div className={styles.technologyIcon}>
                {el?.image1 && (
                  <img
                    decoding="async"
                    loading="lazy"
                    src={el?.image1[0]?.localFile?.publicURL}
                    alt={el?.title}
                    height={80}
                    width={70}
                  />
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Technologies
