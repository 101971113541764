import { graphql } from "gatsby"
import React from "react"
import TextBtnStrip2 from "../components/common/Curious2"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Technologies from "../components/common/Technologies2"
import Benefits from "../components/common/mvc/Benefits"
import Efficient from "../components/common/mvc/Efficient"
import Offering from "../components/common/mvc/Offering"
import Ribon from "../components/common/mvc/Ribon"
import Services from "../components/common/mvc/Services"
import SubServices from "../components/common/mvc/SubServices"
import Banner2 from "../components/services2/Banner2"
import BuildCustomize from "../components/services2/BuildCustomize"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/mvp_development_services_5508c7ac4a.svg"
    />
  )
}
const Mvc = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const minimumValue = data?.strapiPage?.sections[1]
  const subb = data?.strapiPage?.sections[2]
  const ribon = data?.strapiPage?.sections[3]
  const develop = data?.strapiPage?.sections[4]
  const ribon2 = data?.strapiPage?.sections[5]
  const benefits = data?.strapiPage?.sections[6]
  const build = data?.strapiPage?.sections[7]
  const service = data?.strapiPage?.sections[8]
  const tech = data?.strapiPage?.sections[9]
  const faq = data?.strapiPage?.sections[10]
  // console.log(service, 'service')

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner2 strapiData={banner} breadCrumb={data?.strapiPage?.buttons} />
      <Offering strapiData={minimumValue} />
      <SubServices strapiData={subb} />
      <TextBtnStrip2 strapiData={ribon} />
      <Efficient strapiData={develop} />
      <Ribon strapiData={ribon2} />
      <Benefits mvc={true} strapiData={benefits} />
      <BuildCustomize strapiData={build} />
      <Services strapiData={service} />
      <Technologies bg={false} strapiData={tech} />
      <Faqs strapiData={faq} />
    </MainLayout>
  )
}

export const query = graphql`
  query mvp {
    strapiPage(slug: { eq: "mvp" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            url
            title
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default Mvc
